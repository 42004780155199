import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      filtersData: {}
    }
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 5,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "property_ids",
          type: types.components.select,
          width: 4,
          attr: {
            placeholder: "Все адреса",
            options: this.gatePropertyListOptions,
          },
        },
        {
          id: "sigur_server_ids",
          type: types.components.select,
          width: 3,
          attr: {
            placeholder: "Все серверы",
            options: this.serverListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
          onclick: () => {
            this.filtersData.order = 'address';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredGates();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'address'
        },
        {
          id: "server",
          label: "Сервер",
          onclick: () => {
            this.filtersData.order = 'host';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredGates();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'host'
        },
        {
          id: "server_type",
          label: "Тип сервера",
          onclick: () => {
            this.filtersData.order = 'server_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredGates();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'server_type'
        },
        {
          id: "subs",
          label: "Количество абонентов",
          onclick: () => {
            this.filtersData.order = 'contact_count';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredGates();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'contact_count'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.gateList.forEach((gate) => {
        data.push({
          id: gate.cuid,
          columns: [
            {
              id: "address",
              content: {
                id: gate.cuid,
                label: gate.property.address,
              },
            },
            {
              id: "server",
              content: {
                id: gate.cuid,
                label:
                gate.sigur_server.host ?? "-",
              },
            },
            {
              id: "server_type",
              content: {
                id: gate.cuid,
                label:
                gate.sigur_server.type?.name ?? "-",
              },
            },
            {
              id: "subs",
              content: {
                id: gate.cuid,
                label: gate.user_contact_bindings_count,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Подключить адрес",
              icon: "plus-outlined",
              handler: () => this.$router.push({ name: "AddGate" }),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Добавить сервер",
              icon: "plus-outlined",
              handler: () => this.$emit("addServer"),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Снять выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить адрес",
              icon: "trash",
              handler: () => this.removeGates(),
            },
          },
        ],
      };
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onRowClick(rowId) {
      this.$router.push({ name: "Address", params: { gateId: rowId } });
    },
    filterGate(filters) {
      this.filtersData = filters;

      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        this.getFilteredGates();
      }, 2000);
    },
    setGatesPagination() {
      this.initPagination(
        this.gatesMeta.current_page,
        this.gatesMeta.total,
        this.gatesMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredGates();
    },
    getFilteredGates() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.getGates(this.filtersData);
      this.setGatesPagination();
    },
    removeGates() {
      this.$store.dispatch('setIsLoading', true)
      this.gateService.removeGates(this.rowsSelected).then(response => {
        this.$store.dispatch("setNotification", { text: "Контроли доступа успешно удалены." });
        this.getFilteredGates()
        this.rowsSelected = [];
        this.$store.dispatch('setIsLoading', false)
      }).catch((error) => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false)
      })
    }
  },
  mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }

    this.getProperties({ organizationId: this.currentOrganizationId });

    // if (this.gateList.length === 0) {
      this.getGates({ organizationId: this.currentOrganizationId });
    // }

    this.setGatesPagination();
  },
};
