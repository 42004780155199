import httpProvider from 'Providers/http.js';

export default {
    getServers(filters) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/server?' + new URLSearchParams(filters).toString()).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    addServer(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/sigur/server/create', data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    updateServer(serverId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/sigur/server/update/' + serverId, data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    removeServers(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/sigur/server/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    getAccessRules(serverId) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/server/access-rules/' + serverId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getServerTypes() {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/server-type/').then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    }
}