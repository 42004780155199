import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";
import MockAccessRules from "./MockAccessRules.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination, MockAccessRules],
  data() {
    return {
      searchTimeOut: null,
      filtersData: {},
    }
  },
  computed: {
    srvr() {
      return {
        name: this.currentServer ? this.currentServer.host : 'No name',
        status: this.currentServer ? this.currentServer.status : false,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
          {
            id: "srvrerr",
            type: "failed",
            icon: "cross",
            label: "Сервер не работает",
          },
        ],
        count: 3,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "address",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Адреса",
            options: this.propertyListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "section",
          label: "Подъезд",
          onclick: () => {
            this.filtersData.order = 'section';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'section'
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "sigur_rules",
          label: "Доступы (из Сервера)",
          component: {
            type: "link",
          },
          onclick: () => {
            this.filtersData.order = 'sigur_rules';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'sigur_rules'
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sigur_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "section",
              content: {
                id: contact.uid,
                label: contact.section ?? '-',
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "sigur_rules",
              content: {
                id: contact.uid,
                label: this.getContactRules(contact),
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Редактировать сервер",
              icon: "edit",
              handler: () => this.onEditServer(),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить cервер",
              icon: "trash",
              handler: () => this.onDeleteServer(this.$route.params.serverId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Снять выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Изменить верификацию",
              icon: "check",
              handler: () => this.verifySelectedContacts(),
            },
          },
          // {
          //   id: 4,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     colored: "danger",
          //     label: "Удалить строку",
          //     icon: "trash",
          //     handler: () => this.deleteContacts(),
          //   },
          // },
        ],
      };
    },
    currentServer() {
      return this.serverList.filter(server => server.suid === this.$route.params.serverId)[0] ?? null
    },
    server() {
      return {
        host: this.currentServer.host,
        proxy_host: this.currentServer.proxy_host,
        stuid: this.currentServer.type?.stuid,
        login: this.currentServer.login,
        password: this.currentServer.password,
        proxy_login: this.currentServer.proxy_login,
        proxy_password: this.currentServer.proxy_password,
      };
    },
  },
  mounted() {
    this.$store.dispatch('setIsLoading', true)

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      sigurServerId: this.$route.params.serverId
    });

    this.getContacts({
      organizationId: this.currentOrganizationId,
      sigurServerId: this.$route.params.serverId
    }).then(response => {
      this.setContactsPagination();

      if (!this.serverRules[this.currentServer.suid]) {
        this.getAccessRules(this.currentServer.suid).then(response => {
          this.setClientPopupOptions(response.data)
          this.$store.dispatch('setIsLoading', false)
        }).catch(error => {
          this.$store.dispatch('setIsLoading', false)
          console.log(error)
        })
      } else {
        this.setClientPopupOptions(this.serverRules[this.currentServer.suid])
        this.$store.dispatch('setIsLoading', false)
      }
    }).catch(error => {
      this.$store.dispatch('setIsLoading', false)
    });
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    getFilteredContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sigurServerId = this.$route.params.serverId;

      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
      
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sigurServerId = this.$route.params.serverId;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row)
    },
    verifyContact(contactId) {
      this.$store.dispatch('setIsLoading', true)
      this.contactService.verifyContacts({contacts: [{id: contactId}]}).then(response => {
        this.getFilteredContacts();
        this.$store.dispatch('setIsLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false)
      })
    },
    verifySelectedContacts() {
      this.$store.dispatch('setIsLoading', true)
      let data = {contacts: []}
      this.rowsSelected.forEach(value => {
        data.contacts.push({id: value})
      })
      this.contactService.verifyContacts(data).then(response => {
        this.rowsSelected = [];
        this.getFilteredContacts();
        this.$store.dispatch('setIsLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false)
      })
    },
    deleteContacts() {
      this.$store.dispatch('setIsLoading', true)
      this.contactService.removeContacts(this.rowsSelected).then(response => {
        this.getFilteredContacts();
        this.$store.dispatch('setIsLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false)
      })
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page,
        this.contactsMeta.total,
        this.contactsMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
    onServerPopupApprove() {
      this.serverService.updateServer(this.$route.params.serverId, {
        ouid: this.currentOrganizationId,
        host: this.serverPopup.modelValue.host,
        proxy_host: this.serverPopup.modelValue.proxy_host,
        login: this.serverPopup.modelValue.login,
        password: this.serverPopup.modelValue.password,
        stuid: this.serverPopup.modelValue.stuid,
        proxy_login: this.serverPopup.modelValue.proxy_login,
        proxy_password: this.serverPopup.modelValue.proxy_password,
      }).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.initPagination(
            this.serversMeta.current_page,
            this.serversMeta.total,
            this.serversMeta.per_page
          );
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        console.log(error)
        this.serverPopup.opened = false;
      });
    },
  }
};
