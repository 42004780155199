import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";
import MockAccessRules from "./MockAccessRules.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination, MockAccessRules],
  data() {
    return {
      searchTimeOut: null,
      actionValues: {3: false},
      filtersData: {},
      currentServer: {},
    };
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "propertyIds",
          type: types.components.select,
          width: 5,
          attr: {
            placeholder: "Все адреса",
            options: this.propertyListOptions,
          },
        },
        {
          id: "status",
          type: types.components.select,
          width: 3,
          attr: {
            placeholder: "Статус верификации",
            options: [
              {
                id: true,
                label: "Верифицирован",
              },
              {
                id: false,
                label: "Не верифицирован",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
        },
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "section",
          label: "Подъезд",
          onclick: () => {
            this.filtersData.order = 'section';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'section'
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "sigur_rules",
          label: "Доступы (из Сервера)",
          component: {
            type: "link",
          },
          onclick: () => {
            this.filtersData.order = 'sigur_rules';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'sigur_rules',
          style: {
            minWidth: '220px',
          }
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status',
          style: {
            minWidth: '263px',
          }
        },
      ];
    },
    tableRows() {
      const data = [];

      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sigur_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "section",
              content: {
                id: contact.uid,
                label: contact.section ?? '-',
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "sigur_rules",
              content: {
                id: contact.uid,
                label: this.getContactRules(contact),
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Сохранить",
              disabled: !this.actionValues['3'],
              handler: () => this.onSaveContacts(),
            },
          },
          {
            id: 3,
            type: types.actions.checkbox,
            attr: {
              label:
                "Подтверждаю, что данные верны, готов опубликовать их в мобильном приложении жителя",
              handler: () => this.actionValues['3'] = !this.actionValues['3'],
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Верифицировать",
              icon: "check",
              handler: () => this.verifySelectedContacts(false),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить верификацию",
              icon: "check",
              handler: () => this.verifySelectedContacts(false),
            },
          },
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Снять выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
        ],
      };
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onSaveContacts() {
      this.$store.dispatch("deleteTempGateIds");
      this.$store.dispatch("setNotification", { text: "Контроли доступа добавлены" });
      this.$router.push({ name: "Gates" });
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row, !currentValue);
    },
    verifySelectedContacts(status) {
      this.$store.dispatch('setIsLoading', true)
      let data = { contacts: [], status: status };
      this.rowsSelected.forEach((value) => {
        data.contacts.push({ id: value });
      });

      this.contactService
        .verifyContacts(data)
        .then((response) => {
          this.rowsSelected = [];
          this.getFilteredContacts();
          this.$store.dispatch('setIsLoading', false)
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch('setIsLoading', false)
        });
    },
    verifyContact(contactId, status) {
      this.$store.dispatch('setIsLoading', true)
      this.contactService
        .verifyContacts({ contacts: [{ id: contactId }], status: status })
        .then((response) => {
          this.getFilteredContacts();
          this.$store.dispatch('setIsLoading', false)
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch('setIsLoading', false)
        });
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sigurServerContactConfigIds = this.$store.getters.tempGateIds;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page ?? 1,
        this.contactsMeta.total ?? 1,
        this.contactsMeta.per_page ?? 1
      );
    },
    getFilteredContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sigurServerContactConfigIds = this.$store.getters.tempGateIds;
      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
    getContactsWithRules() {
      this.$store.dispatch('setIsLoading', true)
      this.getContacts({
        organizationId: this.currentOrganizationId,
        sigurServerContactConfigIds: this.$store.getters.tempGateIds,
      }).then(response => {
        this.setContactsPagination();
        this.currentServer = this.contactList[0]?.sigur_server_contact_config?.sigur_server;
        
        if (!this.serverRules[this.currentServer.suid]) {
          this.getAccessRules(this.currentServer.suid).then(response => {
            this.setClientPopupOptions(response.data)
            this.$store.dispatch('setIsLoading', false)
          }).catch(error => {
            console.log(error)
            this.$store.dispatch('setIsLoading', false)
          })
        } else {
          this.setClientPopupOptions(this.serverRules[this.currentServer.suid])
          this.$store.dispatch('setIsLoading', false)
        }
      }).catch(error => {
        this.$store.dispatch('setIsLoading', false)
      });
    }
  },
  async mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      sigurServerContactConfigIds: this.$store.getters.tempGateIds,
    });

    this.getContactsWithRules()
  },
};
