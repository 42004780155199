<template>
  <section class="address-list">
    <div class="address-list__filters">
      <Filters v-model="filtersActive" :filters="filters" />
    </div>
    <div class="address-list__list">
      <Table
        v-model="rowsSelected"
        :columns="tableColumns"
        :rows="tableRows"
        @rowClick="onRowClick"
      />
      <Pagination v-model="currentPage" v-bind="pagination" @switchPage="onSwitchPage" />
    </div>
    <div class="address-list__actions">
      <Actions
        :actions="rowsSelected.length ? actions.selected : actions.default"
      />
    </div>
  </section>
</template>

<script>
import Filters from "../../components/Filters.vue";
import Table from "../../components/Table.vue";
import Actions from "../../components/Actions.vue";
import MockGateAddressList from "../../mixins/Mock/MockGateAddressList.js";
import MockPagination from "../../mixins/Mock/MockPagination.js";
import Pagination from "../../components/Pagination.vue";

export default {
  name: "AddressList",
  mixins: [MockGateAddressList, MockPagination],
  components: { Filters, Table, Actions, Pagination },
  data() {
    return {
      filtersActive: {},
      rowsSelected: [],
    };
  },
  watch: {
    filtersActive: {
      handler(value) {
        if (value.property_ids === null) {
          value.property_ids = '';
        }

        if (value.sigur_server_ids === null) {
          value.sigur_server_ids = '';
        }

        this.filterGate(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.address-list {
  &__filters {
    margin: 0px $space-md $space-lg;
  }

  &__list {
    margin: 0px $space-md $space-lg;
  }
}
</style>
