import { createStore } from "vuex";

import auth from "Store/modules/auth.js";
import gate from "Store/modules/gate.js";
import server from "Store/modules/server.js";
import property from "Store/modules/property.js";
import contact from "Store/modules/contact.js";
import notifications from "Store/modules/notifications.js";
import common from "Store/modules/common.js";

export default createStore({
  modules: {
    auth,
    gate,
    server,
    property,
    contact,
    notifications,
    common,
  },
});
