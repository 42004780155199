import Gate from "Pages/Gate/Index.vue";
import AddGate from "Pages/AddGate/Index.vue";
import Contacts from "Pages/AddGate/Contacts.vue";
import ResidentPassCreate from "Pages/AddGate/ResidentPass.vue";
import ResidentPassEdit from "Pages/Address/ResidentPassEdit.vue";
import Components from "Pages/Components.vue";
import Address from "Pages/Address/Index.vue";
import EditAddress from "Pages/Address/Edit.vue";
import Server from "Pages/Server/Index.vue";
import MobileAuth from "Pages/MobileAuth.vue";
import Auth from "Pages/Auth.vue";
import Oidc from "Pages/Oidc.vue";

import auth from "Middleware/auth.js";
import guest from "Middleware/guest.js";

export default [
  {
    path: "/oidc/callback",
    name: "Oidc",
    component: Oidc,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/",
    name: "Auth",
    component: Auth,
    meta: {
      // middleware: [guest]
    }
  },
  {
    path: "/auth/mobile",
    name: "MobileAuth",
    component: MobileAuth,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/gates",
    name: "Gates",
    component: Gate,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/gates/add",
    name: "AddGate",
    component: AddGate,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/gates/resident-pass/add",
    name: "ResidentPassCreate",
    component: ResidentPassCreate,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/gates/resident-pass/edit/:gateId",
    name: "ResidentPassEdit",
    component: ResidentPassEdit,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/components",
    name: "Components",
    component: Components,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/address/:gateId",
    name: "Address",
    component: Address,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/editAddress/:gateId",
    name: "EditAddress",
    component: EditAddress,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/server/:serverId",
    name: "Server",
    component: Server,
    meta: {
      middleware: [auth]
    }
  },
];
