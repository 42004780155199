import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import GateService from "Services/GateService.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData],
  data() {
    return {
      addressList: [this.getBlankAddress()],
      gateService: GateService,
    };
  },
  computed: {
    actions() {
      return {
        default: [
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Следующий шаг",
              disabled: this.addressListFilledLength === 0,
              handler: () => this.toNextStep(),
            },
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отмена",
              // disabled: !this.isAddNewAvailable,
              // icon: "plus-outlined",
              handler: () => this.$router.push({name: 'Gates'}),
            },
          },
        ],
      };
    },
    formList() {
      return [
        {
          id: "address",
          label: "Выберите дом",
          required: true,
          type: types.components.select,
          width: 7,
          attr: {
            placeholder: "Выберите дом",
            options: this.propertyListOptions,
          },
        },
        {
          id: "server",
          label: "Выберите сервер",
          required: true,
          type: types.components.select,
          width: 5,
          attr: {
            placeholder: "Выбрать",
            options: this.serverListOptions,
            // options: [],     // Если не будет опций - покажет кнокку "Добавить сервер" (ниже)
          },
          nooptions: {
            label: "Добавить сервер",
            icon: "plus-outlined",
            handler: () => this.onAddServer(),
          },
        },
      ];
    },
    addressListFilledLength() {
      return this.addressList.reduce((acc, item) => {
        const addr = item.form.address;
        const serv = item.form.server;
        // const file = item.file;

        // if (!addr || !serv || !addr.length || !serv.length || !file) return acc;
        if (!addr || !serv || !addr.length || !serv.length) return acc;

        ++acc;
        return acc;
      }, 0);
    },
    isAddNewAvailable() {
      return this.addressList.length === this.addressListFilledLength;
    },
  },
  methods: {
    getBlankAddress() {
      return {
        id: Math.random(),
        form: {
          address: [],
          server: [],
        },
        // file: null,
        serverStatus: false,
      };
    },
    addAddress() {
      this.addressList.push(this.getBlankAddress());
    },
    removeAddress(id) {
      this.addressList = this.addressList.filter((item) => item.id !== id);
    },
    onAddressChange(address) {
      let serverId = address.form.server ?? null;
      
      this.serverList.forEach(server => {
        if (this.addressList[0] && this.addressList[0].form?.server === serverId) {
          this.addressList[0].serverStatus = server.status;
        }
      })
    },
    getPreparedAdressData() {
      let preparedAddressData = {
        organization_id: this.currentOrganizationId,
        configs: []
      };

      this.addressList.forEach(address => {
        preparedAddressData.configs.push({
          property_id: address.form.address ?? null,
          sigur_server_id: address.form.server ?? null,
          resident_passes: []
          // contacts_file: address.file
        })
      });

      return preparedAddressData;
    },
    toNextStep() {
      this.$store.dispatch('setIsLoading', true);
      this.$store.dispatch('setGateFormData', this.getPreparedAdressData());
      this.$router.push({ name: "ResidentPassCreate" })
      this.$store.dispatch('setIsLoading', false);
      // this.$store.dispatch('setIsLoading', true);
      // this.gateService.addGate(this.getPreparedAdressData()).then(response => {
      //   this.$store.dispatch('setTempGateIds', response.data.sigur_server_contact_config_ids ?? []);
      //   this.$store.dispatch('setIsLoading', false);
      //   this.$router.push({ name: "Contacts" })
      // }).catch(error => {
      //   console.log(error)
      //   this.$store.dispatch('setIsLoading', false);
      // })
    }
  },
  mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }
    
    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }
  }
};
