<template>
  <section class="add">
    <Popup 
      v-if="isExitPopupActive"
      title="Передумали подключать адрес?"
      :hasCancelBtn="true"
      :transparentBtn="true"
      approveBtnLabel="Уйти"
      bodyFontColor="#82879F"
      @approve="onExitApprove"
      @close="onExitClose"
    >
    Если вы уйдете с этой страницы, изменения не сохранятся
  </Popup>
    <Header class="add__header">
      <template v-slot:title>Настроить пропуска</template>
    </Header>
    <div class="config-data">
      <div class="address">
        {{ address.address }}
      </div>
      <div class="server-data">
        <div class="server-address">
          <img :src="serverIcon" alt="">
          <span>{{ address.ip }}</span>
        </div>
        <Chips :chips="address.status ? [address.chips[0]] : [address.chips[1]]" />
      </div>
    </div>

    <section class="passes">
      <div class="main-passes">
        <div class="pass-title">
          <h3>Пропуска на жителя</h3>
          <div class="pass-label">Постоянные</div>
        </div>
        
        <Passes :passes="residentPasses" v-model:modelValue="passesValue.resident" />

        <div class="pass-title">
          <h3>Пропуска на гостя</h3>
          <div class="pass-label">Временные</div>
        </div>
        
        <Passes :passes="guestPasses" v-model:modelValue="passesValue.guest" />
      </div>

      <div class="passes-info">
        <div class="info-block">
          <span class="info-icon">
            i
          </span>
          <p class="info-text">
            Все настройки пропусков будут одинаково отображаться в приложении для всех жителей этого дома
          </p>
        </div>
      </div>
    </section>
    

    <div class="add__actions">
      <Actions :actions="actions.default" />
    </div>
  </section>
</template>

<script>
import Header from "../../components/Header.vue";
import Actions from "../../components/Actions.vue";
import Passes from "../../components/Passes.vue";
import Popup from "../../components/Popup.vue";
import Address from "./Address.vue";
import ServerPopup from "../Popup/Server.vue";
import MockServer from "../../mixins/Mock/MockServer.js";
import ResidentPassCreate from "../../mixins/Mock/ResidentPassCreate.js";
import Chips from "../../components/Chips.vue";
import ServerIcon from '../../../images/icons/server.svg';

export default {
  name: "ResidentPass",
  mixins: [MockServer, ResidentPassCreate],
  components: { Header, ServerPopup, Actions, Address, Chips, Passes, Popup },
  data() {
    return {
      serverIcon: ServerIcon
    }
  },
};
</script>

<style lang="scss" scoped>
.add {
  width: 100%;

  &__header {
    margin: 0px $space-md $space-xxl;
  }

  &__message {
    margin: 0px $space-md $space-md;
  }
}

.config-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 70px;

  .address {
    font-size: 20px;
    color: #707695;
  }

  .server-data {
    display: flex;
    align-items: center;

    .server-address {
      display: flex;
      align-items: center;
      margin-right: 30px;

      img {
        margin-right: 8px;
      }
    }
  }
}

.pass-label {
  font-size: 12px;
  color: #707695;
  background-color: #F2F4F6;
  padding: 5px 10px;
  border-radius: 30px;
}

.pass-title {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 30px;

  h3 {
    font-size: 26px;
    margin-right: 20px;
    margin-bottom: 0;
    font-weight: bolder;
  }
}

section.passes {
  display: flex;
}

.main-passes {
  width: 70%;
}

.passes-info {
  width: 30%;
  padding: 60px 0 0 40px;

  .info-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    color: #707695;
    background-color: #E7F4FF;
    border-radius: 7px;

    .info-icon {
      width: 50px;
      height: 22px;
      border-radius: 22px;
      border: 1px solid #2696F3;
      color: #2696F3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

</style>
