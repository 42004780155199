<template>
  <section class="edit">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />

    <Header class="edit__header">
      <template v-slot:title>Редактировать адрес или сервер</template>
    </Header>

    <div class="edit__form">
      <FormElement
        v-model="editValue.address"
        v-bind="editElementsSettings.address"
      />
      <div class="edit__form-server">
        <div class="edit__form-server-input">
          <FormElement
            v-model="editValue.server"
            v-bind="editElementsSettings.server"
          />
        </div>
        <Button
          type="secondary"
          label="Редактировать сервер"
          icon="edit"
          :handler="onEditServer"
        />
      </div>
    </div>

    <div class="edit__save">
      <Button
        type="primary"
        label="Сохранить"
        icon="edit"
        :handler="onSaveAddress"
      />
    </div>
  </section>
</template>

<script>
import Header from "../../components/Header.vue";
import Button from "../../components/Form/Button.vue";
import MockGateEditAddress from "../../mixins/Mock/MockGateEditAddress.js";
import ServerPopup from "../Popup/Server.vue";
import FormElement from "../../components/Form/Element.vue";

export default {
  name: "EditAddress",
  mixins: [MockGateEditAddress],
  components: { Header, Button, ServerPopup, FormElement },
};
</script>

<style lang="scss" scoped>
.edit {
  width: 100%;

  &__header,
  &__form,
  &__save {
    margin: $space-xxl 0px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: $space-xl;
    max-width: 600px;

    &-server {
      display: flex;
      -webkit-justify-content: flex-end;
      align-items: flex-end;
      -webkit-align-items: flex-end;
      gap: $space-lg;

      &-input {
        flex: 1;
      }
    }
  }
}
</style>
