import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [ServiceData],
  data() {
    return {
      clientPopup: {
        placeholder: "Выберите доступы",
        mode: "multiple",
        options: [],
        client: {},
        selectedValueLabels: [],
        multipleLabel: function (value, select$) {
          let labels = [];

          for (let i = 0; i < value.length; i++) {
            labels.push(value[i].label);
          }

          return labels.join(', ');
        },
        value: [],
        opened: false,
        error: false,
        minBodyHeight: 94,
        restrictedRules: []
      }
    };
  },
  computed: {
    
  },
  methods: {
    onSelectOpen() {
      this.clientPopup.minBodyHeight = this.clientPopup.options.length * 40 + 50;
    },
    onSelectClose() {
      this.clientPopup.minBodyHeight = 94;
      this.clientPopup.error = false;
    },
    onRowClick(id) {
      const client = this.contactList.find((item) => item.uid === id);
      if (!client || !client.sigur_id) return;

      this.clientPopup.value = [];
      this.clientPopup.client = client;

      client.sigur_rules.split(',').forEach(ruleId => {
        this.clientPopup.value.push(ruleId)
      })

      this.clientPopup.opened = true;
    },
    onClientPopupClose() {
      this.clientPopup.opened = false;
    },
    validateAccessRule() {
      let hasFirstLevel = false;
      this.clientPopup.restrictedRules = [];

      this.clientPopup.options.forEach(option => {
        if (this.clientPopup.value.length > 1 && this.clientPopup.value.includes(option.id) && option.level === 1) {
          hasFirstLevel = true;
          this.clientPopup.restrictedRules.push(option.label);
        }
      });

      return hasFirstLevel;
    },
    onClientPopupApprove() {
      this.$store.dispatch('setIsLoading', true);
      this.clientPopup.error = this.validateAccessRule();

      if (this.clientPopup.error) {
        this.$store.dispatch('setIsLoading', false);
        return;
      }

      this.contactService.setContactAccessRules({
        contactId: this.clientPopup.client.uid,
        rule_ids: this.clientPopup.value
      }).then(response => {
        this.$store.dispatch("setNotification", { text: "Запись успешно обновлена" });
        // this.getCurrentGateContacts();
        this.getFilteredContacts();

        this.$store.dispatch('setIsLoading', false);
        this.clientPopup.opened = false;
      }).catch((error) => {
        this.$store.dispatch("setNotification", { text: "Ошибка назначения доступа", type: "cross-outlined" });
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    setClientPopupOptions(rules) {
      rules.forEach(rule => {
        this.clientPopup.options.push({
          id: rule.id,
          label: rule.name,
          level: rule.level
        })
      });
    },
    getContactRules(contact) {
      let rulesNames = [];
      let serverRules = this.serverRules[this.currentServer?.suid];
      
      if (!contact.sigur_rules || !serverRules) {
        return contact.sigur_id ? 'Выберите доступы' : '-';
      }

      let contactRuleIds = contact.sigur_rules.split(',');

      serverRules.forEach((rule, index) => {
        if (contactRuleIds.includes(rule.id.toString())) {
          rulesNames.push(rule.name);
        }
      });

      return rulesNames.join(', ');
    }
  },
};
