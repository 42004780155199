import types from "../../config/types.js";
import ServerService from "Services/ServerService.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [ServiceData, MockPagination],
  data() {
    return {
      serverPopup: {
        mode: types.serverModes.add,
        modelValue: {},
        opened: false,
      },
      serverService: ServerService
    };
  },
  computed: {
    server() {
      return {
        host: "hostmyhostingasap.com",
        proxy_host: "hostmyhostingasap.com",
        login: "логин",
        password: "пароль",
        proxy_login: "логин прокси сервера",
        proxy_password: "пароль прокси сервера",
      };
    },
  },
  methods: {
    onDeleteServer(serverId) {
      this.serverService.removeServers([serverId]).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.$router.push({name: 'Gates'})
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        this.$store.dispatch("setNotification", { text: "Сперва удалите контроь доступа" });
        this.serverPopup.opened = false;
      });
    },
    onAddServer() {
      this.serverPopup.mode = types.serverModes.add;
      this.serverPopup.modelValue = {};
      this.serverPopup.opened = true;
    },
    onEditServer() {
      this.serverPopup.mode = types.serverModes.edit;
      this.serverPopup.modelValue = { ...this.server };
      this.serverPopup.opened = true;
    },
    onServerPopupClose() {
      this.serverPopup.opened = false;
    },
    onServerPopupApprove() {
      if (!this.validate()) {
        this.$store.dispatch("setNotification", { text: "Заполните все поля", type: "cross-outlined" });
        return
      }

      this.serverService.addServer({
        ouid: this.currentOrganizationId,
        host: this.serverPopup.modelValue.host,
        proxy_host: this.serverPopup.modelValue.proxy_host,
        stuid: this.serverPopup.modelValue.stuid,
        login: this.serverPopup.modelValue.login,
        password: this.serverPopup.modelValue.password,
        proxy_login: this.serverPopup.modelValue.proxy_login,
        proxy_password: this.serverPopup.modelValue.proxy_password,
      }).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.initPagination(
            this.serversMeta.current_page,
            this.serversMeta.total,
            this.serversMeta.per_page
          );
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        console.log(error)
        this.serverPopup.opened = false;
      });
    },
    validate() {
      let data = this.serverPopup.modelValue;

      return data.host && data.login && data.password && data.stuid;
    }
  },
};
