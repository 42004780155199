<template>
    <div class="pass-block">
        <h4 class="block-title">Тип действующих пропусков</h4>
        <div class="pass-list" v-if="initialPasses.length > 0">
            <Checkbox class="pass-check" v-for="pass in initialPasses" :key="pass.id" :label="pass.name" v-model="pass.status" @change="checkPass(pass)" />
        </div>
        <div class="block-title">
            Максимальное количество одновременно <br> действующих пропусков на жителя
        </div>
        <div class="pass-limits" v-if="initialPasses.length > 0">
            <template v-for="pass in initialPasses" :key="pass.id">
                <div class="limit-block" v-if="pass.status && !exceptedPasses.includes(pass.name)">
                    <span class="limit-label">{{ pass.name }}</span>
                    <Select :searchable="false" :options="passLimits" v-model="pass.limit" @change="selectLimit(pass)" />
                </div>
            </template>
        </div>
    </div>
</template>
  
  <script>
  
    import Checkbox from './Form/Checkbox.vue';
    import Select from './Form/Select.vue';

    export default {
        name: "Passes",
        components: {Checkbox, Select},
        props: {
            passes: {
                type: Array,
                default: () => []
            },
            modelValue: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                passLimits: [
                    {id: 1, label: 1},
                    {id: 2, label: 2},
                    {id: 3, label: 3},
                    {id: 5, label: 5},
                    {id: 10, label: 10},
                ],
                passesValue: this.modelValue,
                exceptedPasses: ['Face ID', 'Blutooth', 'Bluetooth']
            }
        },
        computed: {
            initialPasses() {
                return this.passes.map(pass => {
                    let modelPass = this.passesValue.find(passValue => pass.id == passValue.id);

                    if (modelPass) {
                        pass.status = true;
                        pass.limit = modelPass.limit;
                    }

                    return pass;
                })
            }
        },
        methods: {
            checkPass(pass) {
                if (pass.status) {
                    this.passesValue.push(pass);
                } else {
                    this.passesValue = this.passesValue.filter(item => item.id!== pass.id);
                }

                this.$emit("update:modelValue", this.passesValue);
            },
            selectLimit(pass) {
                this.passesValue.map(passValue => {
                    if (passValue.id === pass.id) {
                        passValue.limit = pass.limit != null ? pass.limit : 0;
                    }

                    return passValue;
                })

                this.$emit("update:modelValue", this.passesValue);
            }
        },
    };
  </script>
  
  <style lang="scss" scoped>
    .pass-block {
        display: flex;
        flex-direction: column;
        gap: $space-xmd;
        margin: $space-lg $space-md;
        padding: $space-lg;
        border: 1px solid $add-intercom-addres-border-color;
        border-radius: $border-radius-lg;
        margin-bottom: 70px;

        .block-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .pass-list {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .pass-check {
                display: flex;
                align-items: center;
                margin-right: 25px;
            }
        }

        .pass-limits {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .limit-block {
                margin-right: 30px;
                min-width: 120px;

                .limit-label {
                    font-size: 12px;
                    margin-bottom: 10px;
                    color: #707695;
                    display: block;
                }
            }
        }
    }
  </style>
  