import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";
import MockAccessRules from "./MockAccessRules.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination, MockAccessRules],
  data() {
    return {
      // filePopup: {
      //   file: null,
      //   opened: false,
      // },
      searchTimeOut: null,
      currentGate: null,
      filtersData: {}
    };
  },
  computed: {
    address() {
      return {
        address: this.currentAddress,
        ip: this.currentServer ? this.currentServer.host : null,
        status: this.currentServer ? this.currentServer.status : false,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
          {
            id: "srvrerr",
            type: "failed",
            icon: "cross",
            label: "Сервер не работает",
          },
        ],
        count: this.contactList.length ?? 0,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "status",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Статус",
            options: [
              {
                id: true,
                label: "Верифицирован",
              },
              {
                id: false,
                label: "Не верифицирован",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "section",
          label: "Подъезд",
          onclick: () => {
            this.filtersData.order = 'section';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'section',
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "sigur_rules",
          label: "Доступы (из Сервера)",
          component: {
            type: "link",
          },
          onclick: () => {
            this.filtersData.order = 'sigur_rules';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'sigur_rules',
          style: {
            minWidth: '220px',
          }
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status',
          style: {
            minWidth: '263px',
          }
        },
      ];
    },
    tableRows() {
      const data = [];
      
      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sigur_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "section",
              content: {
                id: contact.uid,
                label: contact.section ?? '-',
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "sigur_rules",
              content: {
                id: contact.uid,
                label: this.getContactRules(contact),
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Настроить пропуска",
              icon: "edit",
              handler: () =>
                this.$router.push({
                  name: "ResidentPassEdit",
                  params: { gateId: this.$route.params.gateId },
                }),
            },
          },
          // {
          //   id: 2,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     label: "Добавить абонентов через файл",
          //     icon: "upload-file",
          //     handler: () => {
          //       this.filePopup.opened = true;
          //     },
          //   },
          // },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Отключить адрес",
              icon: "trash",
              handler: () => this.deleteGate(this.$route.params.gateId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Верифицировать",
              icon: "check",
              handler: () => this.verifySelectedContacts(true),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить верификацию",
              icon: "check",
              handler: () => this.verifySelectedContacts(false),
            },
          },
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Снять выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          // {
          //   id: 4,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     colored: "danger",
          //     label: "Удалить строку",
          //     icon: "trash",
          //     handler: () => this.deleteContacts(),
          //   },
          // },
        ],
      };
    },
    currentAddress() {
      return this.currentGate && this.currentGate.property ? this.currentGate.property.address : null;
    },
    currentServer() {
      return this.currentGate && this.currentGate.sigur_server ? this.currentGate.sigur_server : null;
    },
  },
  methods: {
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row, !currentValue)
    },
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    
    // onFilePopupClose() {
    //   this.filePopup.opened = false;
    // },
    // onFilePopupApprove() {
    //   this.gateService.addContacts(this.currentGate.cuid, {
    //     contactsFile: this.filePopup.file
    //   }).then(response => {
    //     this.getCurrentGateContacts();

    //     this.filePopup.opened = false;
    //   }).catch(error => {
    //     console.log(error)
    //   })
      
    // },
    verifySelectedContacts(status) {
      this.$store.dispatch('setIsLoading', true);
      let data = {contacts: [], status: status}
      this.rowsSelected.forEach(value => {
        data.contacts.push({id: value})
      })
      this.contactService.verifyContacts(data).then(response => {
        this.rowsSelected = [];
        this.getCurrentGateContacts();
        this.$store.dispatch('setIsLoading', false);
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    verifyContact(contactId, status) {
      this.$store.dispatch('setIsLoading', true);
      this.contactService.verifyContacts({contacts: [{id: contactId}], status: status}).then(response => {
        this.getCurrentGateContacts();
        this.$store.dispatch('setIsLoading', false);
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    deleteGate(gateId) {
      this.gateService.removeGates([gateId]).then(response => {
          this.getCurrentGateContacts();
          this.$router.push({name: 'Gates'})
      }).catch((error) => {
        console.log(error)
      })
    },
    deleteContacts() {
      this.contactService.removeContacts(this.rowsSelected).then(response => {
        this.getCurrentGateContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sigurServerContactConfigId = this.$route.params.gateId;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    getCurrentGateContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sigurServerContactConfigId = this.$route.params.gateId;

      this.getFilteredContacts();
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page,
        this.contactsMeta.total,
        this.contactsMeta.per_page
      );
    },
    getFilteredContacts() {
      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
  },
  async mounted() {
    this.$store.dispatch('setIsLoading', true);
    await this.gateService.getGate(this.$route.params.gateId).then(response => {
      if (response.data) {
          this.currentGate = response.data
      }
    }).catch((error) => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
    })

    if (!this.serverRules[this.currentServer?.suid]) {
      await this.getAccessRules(this.currentServer?.suid).then(response => {
        this.setClientPopupOptions(response.data)
        this.$store.dispatch('setIsLoading', false);
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    } else {
      this.setClientPopupOptions(this.serverRules[this.currentServer.suid])
      this.$store.dispatch('setIsLoading', false);
    }
    

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    this.getCurrentGateContacts();
  },
};
