<template>
  <section class="intercom">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />
    <Header class="intercom__header">
      <template v-slot:title>Настроить контроль доступа</template>
    </Header>
    <div class="intercom__content">
      <component
        :is="activeTab"
        @addServer="onAddServer"
        @editServer="onEditServer"
      />
    </div>
  </section>
</template>

<script>
import Tabs from "Components/Tabs.vue";
import Header from "Components/Header.vue";
import ContactList from "Pages/AddGate/ContactList.vue";
import ServerList from "Pages/Gate/ServerList.vue";
import ServerPopup from "Pages/Popup/Server.vue";
import MockServer from "Mixins/Mock/MockServer.js";

export default {
  name: "Contacts",
  mixins: [MockServer],
  components: { Tabs, ContactList, ServerList, Header, ServerPopup },
  data() {
    return {
      activeTab: "contactList",
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "contactList",
          label: "Подключенные адреса",
        },
        {
          id: "serverList",
          label: "Серверы",
        },
      ];
    },
  },
  watch: {
    serverPopup: {
      handler(value) {
        
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.intercom {
  width: 100%;

  &__header {
    margin: 0px $space-md $space-xxl;
  }

  &__tabs {
    margin: 0px $space-md $space-xl;
  }

  &__content {
    margin: 0px;
  }
}
</style>
