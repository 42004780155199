import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import GateService from "Services/GateService.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData],
  data() {
    return {
      addressList: [this.getBlankAddress()],
      gateService: GateService,
      passes: [],
      passesValue: {
        guest: [],
        resident: []
      },
      isExitPopupActive: false,
      currentGate: {}
    };
  },
  computed: {
    residentPasses() {
      const passes = this.passes.filter(pass => pass.type === 'resident');
      passes.map(pass => {
        pass.status = false;
        pass.limit = 1;
      })

      return passes;
    },
    guestPasses() {
      const passes = this.passes.filter(pass => pass.type === 'guest');
      passes.map(pass => {
        pass.status = false;
        pass.limit = 1;
      })

      return passes;
    },
    address() {
      return {
        address: this.currentGate?.property?.address,
        ip: this.currentGate?.sigur_server ? this.currentGate?.sigur_server?.host : null,
        status: this.currentGate?.sigur_server ? this.currentGate?.sigur_server?.status : false,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
          {
            id: "srvrerr",
            type: "failed",
            icon: "cross",
            label: "Сервер не работает",
          },
        ],
      };
    },
    actions() {
      return {
        default: [
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Сохранить",
              disabled: this.passesValue.resident.length === 0 ||  !this.addressList[0]?.form?.address || !this.addressList[0]?.form?.server,
              handler: () => this.toNextStep(),
            },
          },
          {
            id: 5,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отмена",
              // disabled: !this.isAddNewAvailable,
              // icon: "plus-outlined",
              handler: () => {this.isExitPopupActive = true},
            },
          },
          {
            id: 6,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Отключить адрес",
              // icon: "trash",
              handler: () => this.deleteGate(this.$route.params.gateId),
            },
          },
        ],
      };
    },
    formList() {
      return [
        {
          id: "address",
          label: "Выберите дом",
          required: true,
          type: types.components.select,
          width: 7,
          attr: {
            placeholder: "Выберите дом",
            options: this.propertyListOptions,
          },
        },
        {
          id: "server",
          label: "Выберите сервер",
          required: true,
          type: types.components.select,
          width: 5,
          attr: {
            placeholder: "Выбрать",
            options: this.serverListOptions,
            // options: [],     // Если не будет опций - покажет кнокку "Добавить сервер" (ниже)
          },
          nooptions: {
            label: "Добавить сервер",
            icon: "plus-outlined",
            handler: () => this.onAddServer(),
          },
        },
      ];
    },
    addressListFilledLength() {
      return this.addressList.reduce((acc, item) => {
        const addr = item.form.address;
        const serv = item.form.server;
        // const file = item.file;

        // if (!addr || !serv || !addr.length || !serv.length || !file) return acc;
        if (!addr || !serv || !addr.length || !serv.length) return acc;

        ++acc;
        return acc;
      }, 0);
    },
    isAddNewAvailable() {
      return this.addressList.length === this.addressListFilledLength;
    },
    selectedServer() {
      const serverId = this.$store.getters.gateFormData?.configs[0]?.sigur_server_id;

      return this.serverList.filter(
        (server) => server.suid === serverId
      )[0]?? {};
    },
    selectedProperty() {
      const propertyId = this.$store.getters.gateFormData?.configs[0]?.property_id;

      return this.propertyList.filter(
        (property) => property.puid === propertyId
      )[0]?? {};
    },
  },
  methods: {
    getBlankAddress() {
      return {
        id: Math.random(),
        form: {
          address: [],
          server: [],
        },
        serverStatus: false
        // file: null,
      };
    },
    addAddress() {
      this.addressList.push(this.getBlankAddress());
    },
    removeAddress(id) {
      this.addressList = this.addressList.filter((item) => item.id !== id);
    },
    onExitApprove() {
      this.isExitPopupActive = false;
      this.$router.push({name: 'Gates'})
    },
    onExitClose() {
      this.isExitPopupActive = false;
    },
    onAddressChange(address) {
      let serverId = address.form.server ?? null;
      
      this.serverList.forEach(server => {
        if (this.addressList[0] && this.addressList[0].form?.server === serverId) {
          this.addressList[0].serverStatus = server.status;
        }
      })
    },
    deleteGate(gateId) {
      this.gateService.removeGates([gateId]).then(response => {
          this.$router.push({name: 'Gates'})
      }).catch((error) => {
        console.log(error)
      })
    },
    toNextStep() {
      this.$store.dispatch('setIsLoading', true);
      const gateData = {
        suid: null,
        puid: null,
        resident_passes: []
      };
      this.addressList.forEach(address => {
        gateData.puid = address.form.address;
        gateData.suid = address.form.server;
      })
 
      this.passesValue.resident.forEach(pass => {
        gateData.resident_passes.push({
          id: pass.id,
          limit: pass.limit
        });
      })
      this.passesValue.guest.forEach(pass => {
        gateData.resident_passes.push({
          id: pass.id,
          limit: pass.limit
        });
      })

      this.gateService.updateGate(this.$route.params.gateId, gateData).then(response => {
        this.$store.dispatch('setIsLoading', false);
        // this.$router.push({ name: "Contacts" })
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    toGate() {
      this.$router.push({
        name: "Address",
        params: { gateId: this.$route.params.gateId },
      })
    }
  },
  async mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }
    
    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    await this.gateService.getGate(this.$route.params.gateId).then(response => {
      if (response.data) {
        this.currentGate = response.data

        this.addressList.map(address => {
          address.form.address = this.currentGate.property?.puid;
          address.form.server = this.currentGate.sigur_server?.suid;
          address.serverStatus = this.currentGate.sigur_server?.status ?? false;

          return address;
        })
        
        this.currentGate.resident_passes.forEach(pass => {
          if (pass.type === 'resident') {
            this.passesValue.resident.push({
              id: pass.id,
              limit: pass.limit
            });
          } else {
            this.passesValue.guest.push({
              id: pass.id,
              limit: pass.limit
            });
          }
        });
      }
    }).catch((error) => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
    })

    this.gateService.getResidentPasses().then(response => {
      this.passes = response.data;
    }).catch(error => {
      console.log(error)
    })
  }
};
