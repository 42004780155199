import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData],
  data() {
    return {
      editValue: {
        address: null,
        server: null,
      },
      currentGate: null
    };
  },
  methods: {
    onSaveAddress() {
      this.gateService
        .updateGate(this.$route.params.gateId, {
          puid: this.editValue.address,
          suid: this.editValue.server,
        })
        .then((response) => {
          this.$router.push({
            name: "Address",
            params: { gateId: this.$route.params.gateId },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onServerPopupApprove() {
      let data = this.serverPopup.modelValue;

      this.serverService
        .updateServer(this.editValue.server, {
          ouid: this.currentOrganizationId,
          host: data.name,
          port: data.port,
          login: data.login,
          password: data.password,
        })
        .then((response) => {
          if (response.data) {
            this.initServers();
          }

          this.serverPopup.opened = false;
        })
        .catch((error) => {
          console.log(error);
          this.serverPopup.opened = false;
        });
    },
    async initServers() {
      await this.gateService.getGate(this.$route.params.gateId).then(response => {
        if (response.data) {
            this.currentGate = response.data
        }
      }).catch((error) => {
          console.log(error)
      })

      this.editValue.server = this.currentServer.suid ?? null;
      this.editValue.address = this.currentProperty.puid ?? null;
    },
  },
  async mounted() {
    await this.initServers();

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }
  },
  computed: {
    editElementsSettings() {
      return {
        address: {
          label: "Адрес",
          required: true,
          type: types.components.select,
          attr: {
            placeholder: "Адрес",
            mode: "single",
            options: this.propertyListOptions,
          },
        },
        server: {
          label: "Сервер",
          required: true,
          type: types.components.select,
          attr: {
            placeholder: "Сервер",
            mode: "single",
            options: this.serverListOptions,
          },
        },
      };
    },
    server() {
      return {
        host: this.selectedServer.host ?? "",
        port: this.selectedServer.port ?? "",
        stuid: this.selectedServer.type?.stuid ?? "",
        login: this.selectedServer.login ?? "",
        password: this.selectedServer.password ?? "",
      };
    },
    selectedServer() {
      return (
        this.serverList.filter(
          (server) => server.suid === this.editValue.server
        )[0] ?? {}
      );
    },
    currentProperty() {
      return this.currentGate && this.currentGate.property ? this.currentGate.property : null;
    },
    currentServer() {
      return this.currentGate && this.currentGate.sigur_server ? this.currentGate.sigur_server : null;
    },
  },
};
