import httpProvider from 'Providers/http.js';

export default {
    getResidentPasses() {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/resident-pass').then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getGates(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/contact-config?' + new URLSearchParams(filters).toString()).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getGate(intercomId) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/contact-config/show/' + intercomId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    addGate(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/sigur/contact-config/create', data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    addContacts(intercomId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/sigur/contact-config/add-contacts/' + intercomId, data, {headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateGate(gateId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/sigur/contact-config/update/' + gateId, data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    removeGates(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/sigur/contact-config/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
}