import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServerService from "Services/ServerService.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      serverService: ServerService,
      searchTimeOut: null,
      filtersData: {}
    };
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 5,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "sigur_server_ids",
          type: types.components.select,
          width: 4,
          attr: {
            placeholder: "Все серверы",
            options: this.serverListOptions,
          },
        },
        {
          id: "status",
          type: types.components.select,
          width: 3,
          attr: {
            placeholder: "Все статусы",
            options: [
              {
                id: 1,
                label: "Работает",
              },
              {
                id: 2,
                label: "Не Работает",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
          onclick: () => {
            this.filtersData.order = 'host';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredServers();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'host'
        },
        {
          id: "type",
          label: "Тип",
          onclick: () => {
            this.filtersData.order = 'type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredServers();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'type'
        },
        {
          id: "subs",
          label: "Количество абонентов",
          onclick: () => {
            this.filtersData.order = 'contact_count';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredServers();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'contact_count'
        },
        {
          id: "status",
          label: "Статус сервера",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredServers();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.serverList.forEach((server) => {
        data.push({
          id: server.suid,
          columns: [
            {
              id: "address",
              content: {
                id: server.suid,
                label: server.host,
              },
            },
            {
              id: "type",
              content: {
                id: server.suid,
                label: server.type?.name,
              },
            },
            {
              id: "subs",
              content: {
                id: server.suid,
                label: server.user_contact_bindings_count,
              },
            },
            {
              id: "status",
              content: {
                id: server.suid,
                label: server.status ? "Сервер работает" : "Сервер не работает",
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Подключить адрес",
              icon: "plus-outlined",
              handler: () => this.$router.push({ name: "AddGate" }),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Добавить сервер",
              icon: "plus-outlined",
              handler: () => this.$emit("addServer"),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Снять выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить серверы",
              icon: "trash",
              handler: () => this.removeSelectedRows(),
            },
          },
        ],
      };
    },
  },
  mounted() {
    if (this.serverList.length === 0) {
      this.getFilteredServers();
    }
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onRowClick(rowId) {
      this.$router.push({ name: "Server", params: { serverId: rowId } });
    },
    removeSelectedRows() {
      this.serverService
        .removeServers(this.rowsSelected)
        .then((response) => {
          if (response.data.status) {
            this.getFilteredServers();
            this.rowsSelected = [];
          }
        })
        .catch((error) => {
          let msg = 'Сперва удалите привязку серверов';
          this.$store.dispatch('setServerErrorMessage', msg)
          this.$store.dispatch("setNotification", { text: msg, type: "cross-outlined" });
        });
    },
    filterServers(filters) {
      this.filtersData = filters;

      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        this.getFilteredServers();
      }, 2000);
    },
    setServersPaginationSettings() {
      this.initPagination(
        this.serversMeta.current_page,
        this.serversMeta.total,
        this.serversMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredServers();
    },
    getFilteredServers() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.getServers(this.filtersData);
      this.setServersPaginationSettings();
    }
  },
};
