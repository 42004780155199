<template>
  <section class="checkbox">
    <icon-base
      :icon-name="iconName"
      width="24"
      height="24"
      class="checkbox__icon"
      @click="onClick"
    />
    <div v-if="label" class="checkbox__label">{{ label }}</div>
  </section>
</template>

<script>
import IconBase from "../../components/Icons/IconBase.vue";

export default {
  name: "Checkbox",
  components: { IconBase },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    partly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    handler: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    iconName() {
      if (!this.modelValue && !this.selected) return "checkbox";
      if (this.partly) return "checkbox-partly";
      return "checkbox-selected";
    },
  },
  methods: {
    onClick() {
     this.$emit('update:modelValue', !this.modelValue);
     this.$emit('change');
     this.handler();
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  gap: $space-xs;

  &__icon {
    cursor: pointer;
  }
}
</style>
