<template>
  <section
    :class="[
      'list',
      {
        'list_flex-row': flexRow,
      },
    ]"
  >
    <div
      v-for="item in list"
      :key="item.id"
      class="list__item"
      :style="`flex:${item.width || 1}`"
    >
      <div class="list__item-label">
        {{ item.label }}
        <span v-if="item.required" class="list__item-label-required">*</span>
      </div>
      <component :is="item.type" v-model="values[item.id]" v-bind="item.attr">
        <template v-slot:nooptions v-if="item.nooptions">
          <icon-base
            :icon-name="item.nooptions.icon"
            :label="item.nooptions.label"
            class="list__item-nooptions"
            @click="item.nooptions.handler"
          />
        </template>
      </component>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <span>Включить параметры прокси:</span>
      <Switcher 
        :on="additionalListEnabledValue" 
        :clickAvailable="true"
        @switcherClick="switcherClicked" 
      />
    </div>
    <template v-if="additionalListEnabledValue">
      <div
        v-for="item in additionalList"
        :key="item.id"
        class="list__item"
        :style="`flex:${item.width || 1}`"
      >
        <div class="list__item-label">
          {{ item.label }}
          <span v-if="item.required" class="list__item-label-required">*</span>
        </div>
        <component :is="item.type" v-model="values[item.id]" v-bind="item.attr">
          <template v-slot:nooptions v-if="item.nooptions">
            <icon-base
              :icon-name="item.nooptions.icon"
              :label="item.nooptions.label"
              class="list__item-nooptions"
              @click="item.nooptions.handler"
            />
          </template>
        </component>
      </div>  
    </template>
  </section>
</template>

<script>
import Input from "./Input.vue";
import Select from "./Select.vue";
import Switcher from "../Switcher.vue";
import IconBase from "../Icons/IconBase.vue";

export default {
  name: "PopupList",
  components: { Input, Select, IconBase, Switcher },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: [],
    },
    additionalList: {
      type: Object,
      default: [],
    },
    flexRow: {
      type: Boolean,
      default: false,
    },
    additionalListEnabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      values: { ...this.modelValue },
      additionalListEnabledValue: this.additionalListEnabled
    };
  },

  watch: {
    values: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
  methods: {
    switcherClicked() {
      this.additionalListEnabledValue = !this.additionalListEnabledValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: $space-lg;
  width: 100%;

  &_flex-row {
    flex-direction: row;
    align-items: flex-end;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $space-xxs;

    &-label {
      color: $form-list-label-color;
      font-size: $font-size-md;
      line-height: $line-height-md;

      &-required {
        color: $color-red;
      }
    }

    &-nooptions {
      padding: $space-xs;
    }
  }

  .add-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    margin-bottom: 12px;
    border: none;
    background: linear-gradient(30deg, #E2FFEB, #E7F4FF);
    cursor: pointer;
  }
}
</style>
