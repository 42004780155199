<template>
  <section class="intercom">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />
    <Header class="intercom__header">
      <template v-slot:title>Управление адресами</template>
    </Header>
    <div class="intercom__tabs">
      <Tabs v-model="activeTab" :tabs="tabs" />
    </div>
    <div class="intercom__content">
      <component
        :is="activeTab"
        @addServer="onAddServer"
        @editServer="onEditServer"
      />
    </div>
  </section>
</template>

<script>
import Tabs from "../../components/Tabs.vue";
import Header from "../../components/Header.vue";
import AddressList from "./AddressList.vue";
import ServerList from "./ServerList.vue";
import ServerPopup from "../Popup/Server.vue";
import MockServer from "../../mixins/Mock/MockServer.js";

export default {
  name: "Intercom",
  mixins: [MockServer],
  components: { Tabs, AddressList, ServerList, Header, ServerPopup },
  data() {
    return {
      activeTab: "addressList",
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "addressList",
          label: "Подключенные адреса",
        },
        {
          id: "serverList",
          label: "Серверы",
        },
      ];
    },
  },
  watch: {
    serverPopup: {
      handler(value) {
        
      },
      deep: true,
    },
  },
  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
.intercom {
  width: 100%;

  &__header {
    margin: 0px $space-md $space-xxl;
  }

  &__tabs {
    margin: 0px $space-md $space-xl;
  }

  &__content {
    margin: 0px;
  }
}
</style>
