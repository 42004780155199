<template>
  <section
    :class="[
      'list',
      {
        'list_flex-row': flexRow,
      },
    ]"
  >
    <div
      v-for="item in list"
      :key="item.id"
      class="list__item"
      :style="`flex:${item.width || 1}`"
    >
      <div class="list__item-label">
        {{ item.label }}
        <span v-if="item.required" class="list__item-label-required">*</span>
      </div>
      <component :is="item.type" v-model="values[item.id]" @change="onChange(item.id, $event)" v-bind="item.attr">
        <template v-slot:nooptions v-if="item.nooptions">
          <icon-base
            :icon-name="item.nooptions.icon"
            :label="item.nooptions.label"
            class="list__item-nooptions"
            @click="item.nooptions.handler"
          />
        </template>
      </component>
    </div>
    <button class="add-item-btn" @click="listServerAdd">
      <img :src="plusIcon" alt="">  
    </button>
  </section>
</template>

<script>
import Input from "../../components/Form/Input.vue";
import Select from "../../components/Form/Select.vue";
import IconBase from "../../components/Icons/IconBase.vue";
import PlusIcon from 'Images/icons/plus.svg';

export default {
  name: "List",
  components: { Input, Select, IconBase },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: [],
    },
    flexRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // values: { ...this.modelValue },
      totalValue: {},
      plusIcon: PlusIcon
    };
  },
  computed: {
    // values: {
    //   get() {
    //     return {
    //       ...this.modelValue,
    //     };
    //   },
    //   set(value) {
    //     // this.$emit("update:modelValue", value);
    //   },
    // },
    values() {
      return { ...this.modelValue }
    }
  },
  // watch: {
  //   values: {
  //     handler(value) {
  //       this.$emit("update:modelValue", value);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    onChange(key, event) {
      if (event) {
        this.totalValue[key] = event;
        this.$emit("update:modelValue", this.totalValue);
      }
    },
    listServerAdd() {
      this.$emit("listServerAdd");
    }
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: $space-lg;
  width: 100%;

  &_flex-row {
    flex-direction: row;
    align-items: flex-end;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $space-xxs;

    &-label {
      color: $form-list-label-color;
      font-size: $font-size-md;
      line-height: $line-height-md;

      &-required {
        color: $color-red;
      }
    }

    &-nooptions {
      padding: $space-xs;
    }
  }

  .add-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    margin-bottom: 12px;
    border: none;
    background: linear-gradient(30deg, #E2FFEB, #E7F4FF);
    cursor: pointer;
  }
}
</style>
